import { ThemeProvider } from "@emotion/react";

import "./App.css";
import "./i18n";
import { Router } from "./navigation";
import { GlobalStyles, theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  );
}

export default App;
