import styled from "@emotion/styled";
import { hideOnDesktop } from "../../theme";

export const StyledA = styled.a<{ hideOnDesktop?: boolean; color?: string }>(
  ({ theme, hideOnDesktop: hd = false, color = theme.colors.secondary }) => [
    {
      fontFamily: theme.fonts.secondary,
      color: color,
      textDecoration: "none",
      fontWeight: 900,
      margin: 16,
    },
    hd && hideOnDesktop,
  ]
);
