/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Column,
  OnEnterTransition,
  Row,
  StyledA,
  SubTitle,
  Title,
  useTransition,
} from "../../components";
import { desktop, showOnDesktop, theme } from "../../theme";

export const One: React.FC<{}> = () => {
  const [titleCss, titleTransition] = useTransition("slide-left");
  const [jarsCss, jarsTransition] = useTransition("slide-bottom");
  const { t } = useTranslation();

  return (
    <Row
      css={(theme) => ({
        padding: "32px 10%",
      })}
    >
      <Column flexBasis="max-content">
        <OnEnterTransition transition={titleTransition}>
          <Title
            primary={false}
            primaryColor
            css={[
              titleCss,
              {
                textShadow: "none",
                color: theme.colors.bgBlack,
              },
            ]}
          >
            {t("one.title")}
            <strong> lifestyle</strong>.
          </Title>
        </OnEnterTransition>
        <SubTitle center css={{ color: theme.colors.bgBlack }}>
          {t("one.text.1")}
        </SubTitle>
        <SubTitle center css={{ color: theme.colors.bgBlack, marginTop: 16 }}>
          {t("one.text.2")}
          <StyledA
            href="https://c96717-4.myshopify.com/"
            css={{ margin: 0, zIndex: 10 }}
          >
            online shop
          </StyledA>
        </SubTitle>
        <OnEnterTransition transition={jarsTransition}>
          <Row css={{ justifyContent: "center", gap: "40px" }}>
            <LazyLoadImage
              src="/assets/images/lifestyle.jpg"
              css={[
                jarsCss,
                {
                  width: "100%",
                  borderRadius: 25,
                  marginTop: 18,
                  [desktop]: { width: "30%" },
                },
              ]}
              alt="lifestyle mug"
            />
            <LazyLoadImage
              src="/assets/images/lifestyle-2.jpg"
              css={[
                jarsCss,
                showOnDesktop,
                {
                  width: "30%",
                  height: "auto",
                  borderRadius: 25,
                  marginTop: 18,
                },
              ]}
              alt="lifestyle food"
            />
          </Row>
        </OnEnterTransition>
      </Column>
    </Row>
  );
};
