import { Translation } from "./en";

export const de: Translation = {
  title: "Willkommen bei ONE Nation",
  subtitle: "Entdecke unsere Standorte in München.",
  one: {
    title: "It's not just a community, it's a ",
    text: {
      1: "Willkommen bei ONE Nation, einem revolutionären Fusion-Hotspot in München, der nahtlos ein Hybridrestaurant und Fitnessstudio kombiniert. Erlebe eine einzigartige kulinarische Reise mit Aromen aus Subsahara, Asien und dem Mittelmeerraum. Unser einzigartiger Ort bietet dynamisches Gruppentraining für ein ganzheitliches Lebensstilerlebnis. Begleite uns bei der Fusion verschiedener Küchen und belebendem Fitnesstraining, und schaffe eine Atmosphäre von Vitalität und Wohlbefinden.",
      2: "Besuche unsere ",
    },
  },
  locations: {
    main: {
      discover: "",
    },
  },
  menu: {
    subtitle:
      "Unsere Grundwerte: <1>Nachhaltigkeit</1>, <3>Qualität</3> und <5>Service</5>",
    description:
      "Unsere Restaurants laden dich zu einem <1>gastronomischen Abenteuer</1> ein und bieten eine Fusion von sub-saharischer und mediterraner Küche. Vom Frühstück bis zum Abendessen ist unsere Speisekarte ein <3>Teppich aus Aromen</3> und präsentiert Köstlichkeiten aus der ganzen Welt. Genieße köstliche Gerichte, handwerklich präzise zubereitet, wobei jeder Bissen eine <5>Symphonie von Geschmacksrichtungen und Texturen</5> ist. Unsere Verpflichtung, eine vielfältige und verlockende Speisekarte anzubieten, stellt sicher, dass für jeden Gaumen etwas dabei ist und Ihr kulinarisches Erlebnis wirklich außergewöhnlich wird.",
    examples:
      "Protein Bowls, Wraps, Acai Bowls, Smoothies, Kaffee, Säfte und mehr!",
    orderOn: "Bestelle unter:",
  },
  fitness: {
    title: "Trainiere mit uns",
    p1: "ONE Nation bietet vielfältige Trainingseinheiten, sowohl drinnen als auch draußen, die auf Ihren Lebensstil angepassst sind, von HIIT bis Yoga. Unser Konzept vereint Wissen aus Medizin und Sportwissenschaft und berücksichtigt die Unvollkommenheiten des Körpers. Wir bieten Anleitung, um die Herausforderungen des modernen Lebensstils zu bewältigen.",
    p2: "Jenseits des Fitnessbereichs könnst du nach deiner Einheit die Annehmlichkeiten eines Kaffees oder einer gesunden Mahlzeit am selben Ort genießen. Unser effizientes Training ist darauf ausgerichtet, positive Veränderungen im Leben herbeizuführen und legt dabei besonderen Wert auf Verletzungsprävention. Schließe dich uns für ein ganzheitliches Wohlfühlerlebnis bei ONE Nation an.",
  },
  questions: {
    1: "Sie wollen ein <1>ganzheitliches Fitnesskonzept</1> ohne Risiken für Ihre Gesundheit?",
    2: "Verspüren Sie <1>Schmerzen</1> bei bestimmten Fitnessübungen?",
    3: "Sie sind sich nicht sicher, ob Sie ihre Übungen im Fitnessstudio <1>richtig</1> und ohne Verletzungsgefahr ausführen?",
    4: "Oder <1>hatten Sie bereits eine Verletzung</1> und möchten wieder zu Ihrem ursprünglichen Fitnesslevel zurückkehren?",
  },
  concept: {
    1: "Unser System wurde durch die Einbeziehung von Wissen und Techniken aus mehreren Disziplinen entwickelt und vereint Konzepte auf einzigartige Weise.",
    2: "Das Ergebnis ist ein hochwirksames und vollständiges Präventions- und Trainingssystem für jeden Einzelnen, der bereit ist, eine Veränderung in seinem Leben vorzunehmen.",
  },
  solution: {
    1: "<0>Lernen Sie</0>, sich beim Sport sicher und ergonomisch <2>zu bewegen.</2>",
    2: "<0>Lernen Sie</0>, mögliche Verletzungen während des Trainings <2>zu vermeiden.</2>",
    3: "<0>Lernen Sie</0>, Ihre ursprüngliche Leistungsfähigkeit <2>nach einer Verletzung gezielt und strukturiert wiederherzustellen.</2>",
  },
  contact: {
    title: "Kontaktieren Sie uns ",
  },
};
