/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { Column, FlowBox, Image, Text } from "../../components";
import { desktop } from "../../theme";

export const Concept: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Column
      css={(theme) => ({
        backgroundColor: theme.colors.secondary,
        padding: "32px 10%",
        borderTop: `1px solid ${theme.colors.secondary}`,
      })}
    >
      <FlowBox
        css={{ [desktop]: { justifyContent: "space-between", width: "100%" } }}
      >
        <Column
          flexBasis="70%"
          css={{ marginBottom: 16, [desktop]: { paddingRight: 16 } }}
        >
          <Text
            css={(theme) => ({
              color: theme.colors.white,
            })}
          >
            {t("concept.1")}
          </Text>
          <Text
            css={(theme) => ({
              color: theme.colors.white,
            })}
          >
            {t("concept.2")}
          </Text>
        </Column>
        <Column flexBasis="fit-content" css={{ alignItems: "center" }}>
          <Image
            transition="slide-right"
            css={{ width: 150 }}
            image="one-nation-logo.webp"
            alt="logo"
          />
        </Column>
      </FlowBox>
    </Column>
  );
};
