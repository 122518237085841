import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Anchored } from "./navigation";

const StyledAnchored = styled(Anchored)((theme) => ({}));

export const Section: React.FC<{
  id: string;
  css?: SerializedStyles;
  children: React.ReactNode;
}> = ({ id, children, css }) => {
  return (
    <StyledAnchored as="section" id={id}>
      {children}
    </StyledAnchored>
  );
};
