/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ImageBackground,
  Row,
  Section,
  StyledA,
  SubTitle,
  Title,
} from "../components";
import { AppStore, GPlay } from "../components/LinksToApp";
import { desktop } from "../theme";
import {
  Concept,
  Contact,
  Fitness,
  Menu,
  One,
  Questions,
  Solution,
} from "./sections";

export function useIsMobile() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
}

export const HomePage = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <Row
        css={(theme) => ({
          height: "550px",
          alignItems: "flex-end",
          paddingBottom: "32px",
          justifyContent: "center",
          flex: "1 0 auto",
          position: "relative",
          background: "rgba(0, 0, 0, 0.65)",
          [desktop]: {
            height: "85vh",
            alignItems: "center",
            background: "transparent",
          },
        })}
      >
        <ImageBackground
          isMobile={isMobile}
          imageList={
            isMobile
              ? [
                  "/assets/images/bg-mobile-1.jpeg",
                  "/assets/images/bg-mobile-2.jpg",
                  "/assets/images/bg-mobile-3.jpeg",
                  "/assets/images/bg-mobile-4.jpeg",
                  "/assets/images/bg-mobile-5.jpeg",
                ]
              : [
                  "/assets/images/bg-1.jpg",
                  "/assets/images/bg-2.jpg",
                  "/assets/images/bg-3.jpg",
                  "/assets/images/bg-4.jpg",
                  "/assets/images/bg-5.jpg",
                ]
          }
        >
          <Column
            css={{
              width: "100%",
              bottom: "0px",
              position: "absolute",
              [desktop]: {
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 15%)",
              },
            }}
          >
            <Column
              css={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Title
                css={(theme) => ({
                  marginBottom: 8,
                })}
              >
                {t("title")}
                <span css={(theme) => ({ color: theme.colors.white })}>.</span>
              </Title>
              <SubTitle css={{ textAlign: "center", marginBottom: "16px" }}>
                {t("subtitle")}
              </SubTitle>
              <Row>
                <SubTitle
                  css={{ textAlign: "center", padding: "16px", flex: 1 }}
                >
                  <StyledA
                    href="https://maps.app.goo.gl/YHRqEGakczUJ77t79"
                    css={{ margin: 0 }}
                  >
                    One Nation
                    <br /> Mini Pavillon
                  </StyledA>
                  <br />
                  Lenbachpl. 7a
                  <br />
                  80333 Munich
                </SubTitle>
                <SubTitle
                  css={{
                    textAlign: "center",
                    borderLeft: "1px solid gray",
                    padding: "16px",
                    flex: 1,
                  }}
                >
                  <StyledA
                    href="https://maps.app.goo.gl/94XGbbmUpkKsAvv48"
                    css={{ margin: 0 }}
                  >
                    One Nation <br />
                    Immortl
                  </StyledA>
                  <br />
                  Rosenheimerstrasse 145i
                  <br />
                  81671 Munich
                </SubTitle>
              </Row>
            </Column>
          </Column>
        </ImageBackground>
        <Row
          css={{
            position: "absolute",
            bottom: "auto",
            top: "50%",
            width: "100%",
            justifyContent: "center",
            gap: "8px",
            zIndex: 102,
            [desktop]: {
              justifyContent: "end",
              paddingRight: "16px",
              bottom: "32px",
              top: "auto",
            },
          }}
        >
          <AppStore />
          <GPlay />
        </Row>
      </Row>
      <div
        css={(theme) => ({
          background: theme.colors.white,
          zIndex: 102,
          position: "relative",
        })}
      >
        <div
          css={{
            position: "sticky",
            top: 20,
            display: "flex",
            flexDirection: "row",
            pointerEvents: "none",
            zIndex: 102,
            height: 150,
            [desktop]: {
              height: 600,
            },
          }}
        >
          <div css={{ flex: 1 }} />
          <div
            css={{
              right: 0,
              height: 150,
              width: 150,
              zIndex: 102,
              pointerEvents: "none",
              [desktop]: {
                height: 600,
                width: 600,
              },
              position: "absolute",
              backgroundImage: "url(/assets/images/citrus_background.webp)",
              backgroundSize: "cover",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              opacity: "20%",
            }}
          />
        </div>
        <div
          css={{ marginTop: -150, zIndex: 103, [desktop]: { marginTop: -600 } }}
        >
          <Section id="one">
            <One />
          </Section>
        </div>
        <Section id="menu">
          <Menu />
        </Section>
      </div>
      <Section id="fitness">
        <Fitness />
      </Section>
      <Section id="questions">
        <Questions />
      </Section>
      <Section id="concept">
        <Concept />
      </Section>
      <Section id="solution">
        <Solution />
      </Section>
      <Section id="contact">
        <Contact />
      </Section>
    </>
  );
};
