/** @jsxImportSource @emotion/react */

import React from "react";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import {
  OnEnterTransition,
  Transitions,
  useTransition,
} from "./css-transitions";

export const Image: React.FC<
  Omit<LazyLoadImageProps, "src"> & {
    image: string;
    transition?: Transitions;
  }
> = ({ image, alt, transition, ...props }) => {
  const [css, cssTransition] = useTransition(transition || "none");

  return (
    <OnEnterTransition transition={cssTransition}>
      <LazyLoadImage
        css={[css, { borderRadius: 25, width: "100%" }]}
        src={`/assets/images/${image}`}
        alt={alt}
        {...props}
      />
    </OnEnterTransition>
  );
};
