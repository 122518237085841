/** @jsxImportSource @emotion/react */

import { Trans, useTranslation } from "react-i18next";
import {
  Column,
  DownloadButton,
  FlowBox,
  Image,
  Row,
  StyledA,
  SubTitle,
  Title,
} from "../../components";
import { desktop } from "../../theme";

export const Menu: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Column
      css={(theme) => ({
        padding: "32px 10%",
        borderTop: `1px solid ${theme.colors.secondary}`,
      })}
    >
      <Row css={{ position: "relative" }}>
        <Column flexBasis="max-content">
          <Title css={{ [desktop]: {} }}>Explore our culinary world</Title>
          <SubTitle
            center
            css={(theme) => ({ color: theme.colors.bgBlack, marginBottom: 16 })}
          >
            <Trans i18nKey="menu.subtitle">
              Our core values: <strong>Sustainability</strong>,
              <strong>Quality</strong> and
              <strong>service</strong>
            </Trans>
          </SubTitle>

          <SubTitle center css={(theme) => ({ color: theme.colors.bgBlack })}>
            <Trans i18nKey="menu.description">
              {"Our restaurants invites you on a "}
              <strong>gastronomic adventure</strong>, offering a fusion of
              sub-Saharan and Mediterranean cuisine. From breakfast to dinner,
              our menu is a <strong>tapestry of flavors</strong>
              {
                ", showcasing delicacies from around the world. Savor mouthwatering dishes crafted with precision, each bite a "
              }
              <strong>symphony of tastes and textures</strong>. Our commitment
              to providing a diverse and enticing menu ensures that there's
              something for every palate, making your dining experience truly
              exceptional.
            </Trans>
          </SubTitle>
          <SubTitle
            center
            css={(theme) => ({ color: theme.colors.bgBlack, marginTop: 16 })}
          >
            {t("menu.examples")}
          </SubTitle>
          <SubTitle
            center
            css={(theme) => ({ color: theme.colors.bgBlack, marginTop: 16 })}
          >
            {t("menu.orderOn")}:
          </SubTitle>
          <Row css={{ justifyContent: "center" }}>
            <ul
              css={(theme) => ({
                color: theme.colors.secondary,
                display: "flex",
                flexDirection: "row",
                listStyle: "none",
                padding: 0,
                margin: 0,
                marginTop: 16,
              })}
            >
              <li>
                <StyledA href="https://www.ubereats.com/de/store/one-nation/KG9MhcWLVHKzeomB3iww3Q?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522KG9MhcWLVHKzeomB3iww3Q%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxlbmJhY2hwbGF0eiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpZWktTU19GMW5rY1JOQjRuNXV0SFlFbyUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E0OC4xNDA3NzYxJTJDJTIybG9uZ2l0dWRlJTIyJTNBMTEuNTY4ODgxNSU3RA%3D%3D&ps=1&utm_campaign=place-action-link&utm_medium=organic&utm_source=google">
                  Uber Eats
                </StyledA>
              </li>
              <li>
                <StyledA href="https://www.lieferando.de/en/menu/one-nation">
                  Lieferando
                </StyledA>
              </li>
            </ul>
          </Row>
        </Column>
      </Row>
      <Row css={{ marginTop: 26, justifyContent: "center", zIndex: 103 }}>
        <div css={{ [desktop]: { width: "66%" } }}>
          <Image transition="slide-bottom" image="bowls.jpeg" alt="bowls" />
        </div>
      </Row>
      <FlowBox css={{ marginTop: 16 }}>
        <Column
          flexBasis="100%"
          css={{ justifyContent: "center", alignItems: "center" }}
        >
          <DownloadButton href="/assets/files/menu.pdf">
            Download Menu pdf
          </DownloadButton>
        </Column>
      </FlowBox>
    </Column>
  );
};
