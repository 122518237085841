/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Column, FlowBox, Title } from "../../components";
import { ReactComponent as EnvelopeIcon } from "../../svgs/envelope.svg";
import { ReactComponent as InstagramIcon } from "../../svgs/instagram.svg";
import { desktop } from "../../theme";

export const Contact: React.FC<{}> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Column
      css={(theme) => ({
        backgroundColor: theme.colors.secondary,
        padding: "32px 10%",
        borderTop: `1px solid ${theme.colors.secondary}`,
      })}
    >
      <Title
        css={(theme) => ({
          color: theme.colors.bgBlack,
          marginTop: 0,
          [desktop]: { marginBottom: 32, textAlign: "start" },
        })}
      >
        {t("contact.title")}
      </Title>
      <FlowBox
        css={{
          alignItems: "center",
          [desktop]: { justifyContent: "space-between", padding: "0 30%" },
        }}
      >
        <a
          css={(theme) => ({
            fontSize: theme.fontSizes.medium,
            textDecoration: "none",
            color: "black",
            display: "flex",
            alignItems: "center",
          })}
          href="mailto:info@one-nation.one"
        >
          <EnvelopeIcon height={theme.fontSizes.medium} />
          <span css={{ marginLeft: 4 }}>info@one-nation.one</span>
        </a>
        <a
          css={{ color: "black" }}
          href="https://www.instagram.com/one_nation_munich/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon height={theme.fontSizes.giant} />
        </a>
      </FlowBox>
    </Column>
  );
};
