/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import {
  Column,
  FlowBox,
  OnEnterTransition,
  SubTitle,
  useTransition,
} from "../components";
import { desktop } from "../theme";

const SFooter = styled.nav(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.colors.bgBlack,
  padding: "32px 5% 65px 5%",
  borderTop: `1px solid ${theme.colors.secondary}`,

  [desktop]: {
    padding: "32px 10%",
  },
}));

export const Footer = () => {
  const [poweredCss, poweredTransition] = useTransition("opacity");
  return (
    <SFooter>
      <FlowBox
        css={{
          justifyContent: "space-between !important",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Link to="/">
          <FlowBox css={{ alignItems: "center" }}>
            <LazyLoadImage
              src="/assets/images/one-nation-logo.webp"
              css={{ height: 100 }}
              alt="app logo"
            />
            <OnEnterTransition transition={poweredTransition}>
              <LazyLoadImage
                src="/assets/images/powered-by.webp"
                css={css({ height: 30, margin: 4 }, poweredCss)}
                alt="powered by"
              />
            </OnEnterTransition>
          </FlowBox>
        </Link>
        <SubTitle
          css={(theme) => ({
            color: theme.colors.secondary,
            textAlign: "center",
          })}
        >
          MINI PAVILLION ∙ LENBACHPLATZ 7A ∙ 80333 MUNICH
        </SubTitle>
        <Column
          css={{
            padding: 16,
            "> a": {
              textDecoration: "none",
              color: "white",
              fontWeight: 600,
            },
          }}
        >
          <Link to="/legal">LEGAL NOTICE</Link>
          <Link to="/privacy">PRIVACY PROTECTION</Link>
        </Column>
      </FlowBox>
    </SFooter>
  );
};
