/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { Trans } from "react-i18next";
import { Column, FlowBox, Row, SubTitle } from "../../components";
import { desktop } from "../../theme";

const Text = styled(SubTitle)(({ theme }) => ({
  padding: 2,
  paddingTop: 8,
  textAlign: "center",
  minHeight: 50,
  color: theme.colors.white,
  [desktop]: {
    fontSize: theme.fontSizes.medium,
    padding: 4,
    minHeight: 150,
  },
}));

const SColumn = styled(Column)({
  justifyContent: "end",
  flexBasis: "50%",
});

export const Questions = () => (
  <FlowBox
    css={{
      minHeight: "70vh",
      padding: "32px 10% 0 10%",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundImage: "url('/assets/images/woman-dumbell.jpeg')",
      backgroundAttachment: "fixed",
      justifyContent: "flex-end",
      [desktop]: {
        minHeight: "50vh",
      },
    }}
  >
    <Row css={{ flex: 0, [desktop]: { flex: 1 } }}>
      <SColumn>
        <Text>
          <Trans i18nKey="questions.1">
            Do you want a <strong>holistic fitness</strong> concept without
            risks to your health?
          </Trans>
        </Text>
      </SColumn>
      <SColumn>
        <Text>
          <Trans i18nKey="questions.2">
            Do you <strong>feel pain</strong> during certain fitness exercises?
          </Trans>
        </Text>
      </SColumn>
    </Row>
    <Row css={{ flex: 0, [desktop]: { flex: 1 } }}>
      <SColumn>
        <Text>
          <Trans i18nKey="questions.3">
            Are you not sure if you are doing your exercises in the gym{" "}
            <strong>correctly</strong> and without risk of injury?
          </Trans>
        </Text>
      </SColumn>
      <SColumn>
        <Text>
          <Trans i18nKey="questions.4">
            Or have you already <strong>had an injury</strong> and want to get
            back to your original fitness level?
          </Trans>
        </Text>
      </SColumn>
    </Row>
  </FlowBox>
);
