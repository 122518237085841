export const en = {
  title: "Welcome to ONE Nation",
  subtitle: "Discover Our Locations in Munich",
  one: {
    title: "It's not just a community, it's a ",
    text: {
      1: "Welcome to ONE Nation, a revolutionary fusion destination in Munich, seamlessly blending a hybrid restaurant and gym. Experience a unique culinary journey with sub-Saharan, Asian, and Mediterranean flavors. Our one-of-a-kind location offers dynamic group training, providing a holistic lifestyle experience. Join us for the fusion of diverse cuisines and invigorating fitness, creating an atmosphere of vitality and well-being.",
      2: "Don't hesitate to visit our ",
    },
  },
  locations: {
    main: {
      discover:
        "Experience the flagship location, a hybrid concept of a café-style restaurant and a gym.",
    },
  },
  menu: {
    subtitle:
      "Our core values: <1>Sustainability</1>, <3>Quality</3> and <5>Service</5>",
    description:
      "Our restaurants invites you on a <1>gastronomic adventure</1>, offering a fusion of sub-Saharan and Mediterranean cuisine. From breakfast to dinner, our menu is a <3>tapestry of flavors</3>, showcasing delicacies from around the world. Savor mouthwatering dishes crafted with precision, each bite a <5>symphony of tastes and textures</5>. Our commitment to providing a diverse and enticing menu ensures that there's something for every palate, making your dining experience truly exceptional.",
    examples:
      "Protein bowls, wraps, acai bowls, smoothies, coffee, juices and more!",
    orderOn: "Order On",
  },
  fitness: {
    title: "Train with us",
    p1: "ONE Nation offers diverse training sessions, indoors or outdoors, tailored to your lifestyle, from HIIT to yoga. Our concept blends knowledge from medical and sports science, acknowledging the imperfections of the body. We provide guidance to overcome modern lifestyle challenges.",
    p2: "Beyond fitness, enjoy the convenience of coffee or a healthy meal in the same location after your session. Our efficient training is designed for positive life changes, emphasizing injury prevention. Join us for a holistic well-being experience at ONE Nation.",
  },
  questions: {
    1: "Do you want a <1>holistic fitness</1> concept without risks to your health?",
    2: "Do you <1>feel pain</1> during certain fitness exercises?",
    3: "Are you not sure if you are doing your exercises in the gym <1>correctly</1> and without risk of injury?",
    4: "Or have you already <1>had an injury</1> and want to get back to your original fitness level?",
  },
  concept: {
    1: "Our system has been designed through incorporating knowledge and techniques from multiple disciplines, uniting concepts uniquely together.",
    2: "The result is a highly effective and complete system of prevention and training for every individual ready to make a change in their life.",
  },
  solution: {
    1: "<0>Learn to</0> move safely and ergonomically <2>while exercising.</2>",
    2: "<0>Learn to</0> avoid potential injuries <2>while exercising.</2>",
    3: "<0>Learn to</0> restore your original performance after injury <2>in a targeted and structured way.</2>",
  },
  contact: {
    title: "Contact Us",
  },
};

export type Translation = typeof en;
