/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import {
  Column,
  FlowBox,
  Image,
  Row,
  Title,
  Text as UText,
} from "../../components";
import { Anchor } from "../../components/navigation";
import { desktop } from "../../theme";

const Text = styled(UText)(({ theme }) => ({
  color: theme.colors.white,
  fontSize: 16,
}));
export const Fitness: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Column
      css={(theme) => ({
        backgroundColor: theme.colors.secondary,
        padding: "32px 10%",
        borderTop: `1px solid ${theme.colors.secondary}`,
      })}
    >
      <Anchor id="train-with-us" />
      <Title
        css={(theme) => ({
          color: theme.colors.white,
          [desktop]: { textAlign: "center" },
        })}
      >
        {t("fitness.title")}
      </Title>
      <FlowBox>
        <Column
          flexBasis="70%"
          css={{ marginBottom: 16, [desktop]: { paddingRight: 16 } }}
        >
          <Image transition="slide-left" image="fighting.webp" alt="fighting" />
          <Text>{t("fitness.p1")}</Text>
          <Text>{t("fitness.p2")}</Text>
          <Image transition="slide-bottom" image="fun.webp" alt="fun" />
          <Row
            css={(theme) => ({
              marginTop: 16,
              [desktop]: {
                width: "50%",
                marginLeft: "25%",
                display: "flex",
                justifyContent: "center",
              },
            })}
          >
            <Carousel>
              <Image image="singing.webp" alt="singing" />
              <Image image="gloves.webp" alt="gloves" />
              <Image image="yoga.webp" alt="yoga" />
            </Carousel>
          </Row>
        </Column>
        <Column flexBasis="30%">
          <div data-eversports-widget-id="df03b004-486f-42fe-bce3-6eb48876af56"></div>
        </Column>
      </FlowBox>
    </Column>
  );
};
