/** @jsxImportSource @emotion/react */

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  FlowBox,
  OnEnterTransition,
  Row,
  Spacer,
  useTransition,
} from "../components";
import { ReactComponent as InstagramIcon } from "../svgs/instagram.svg";
import { desktop, hideOnDesktop } from "../theme";

import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { StyledA } from "../components/navigation";
import { ReactComponent as HomeIcon } from "../svgs/home.svg";
import { ReactComponent as SilverwareIcon } from "../svgs/silverware.svg";
import { ReactComponent as WeightLifterIcon } from "../svgs/weight-lifter.svg";

const Nav = styled.nav(({ theme }) => [
  {
    position: "absolute",
    top: 0,
    maxWidth: "100vw",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 1000,
  },
]);

const MobileBottomNav = styled.nav(({ theme }) => [
  {
    position: "fixed",
    display: "flex",
    bottom: 0,
    height: 65,
    width: "100%",
    background: theme.colors.white,
    borderTop: `1px solid ${theme.colors.secondary}`,
    zIndex: 1000,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  hideOnDesktop,
]);

const StyledLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.fonts.secondary,
  color: theme.colors.primary,
  textDecoration: "none",
  fontWeight: 900,
  margin: 16,
}));
const StyledMobileLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.fonts.secondary,
  color: theme.colors.secondary,
  textDecoration: "none",
  fontSize: theme.fontSizes.small,
  fontWeight: 900,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
const StyledMobileAnchor = styled.a(({ theme }) => ({
  fontFamily: theme.fonts.secondary,
  color: theme.colors.secondary,
  textDecoration: "none",
  fontSize: theme.fontSizes.small,
  fontWeight: 900,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const NavBar = () => {
  const [poweredCss, poweredTransition] = useTransition("opacity");
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <header>
      <Nav>
        <Row
          css={{
            padding: "8px 16px",
            justifyContent: "center",
            [desktop]: { justifyContent: "flex-start" },
          }}
        >
          <FlowBox css={{ alignItems: "center" }}>
            <FlowBox css={{ alignItems: "center" }}>
              <Link to="/">
                <LazyLoadImage
                  src="/assets/images/one-nation-logo.webp"
                  css={{ height: 100 }}
                  alt="app logo"
                />
              </Link>
              <Column>
                <OnEnterTransition transition={poweredTransition}>
                  <LazyLoadImage
                    src="/assets/images/powered-by.webp"
                    css={css({ height: 30, margin: 4 }, poweredCss)}
                    alt="powered by"
                  />
                </OnEnterTransition>
                <OnEnterTransition transition={poweredTransition}>
                  <Column
                    css={{
                      color: "white",
                      textDecoration: "none",
                      margin: 4,
                      alignItems: "center",
                      fontSize: 10,
                    }}
                  >
                    <span>Sponsored by</span>
                    <LazyLoadImage
                      src="/assets/images/fightr-logo.webp"
                      css={css({ height: 50 }, poweredCss)}
                      alt="fighr logo"
                    />
                  </Column>
                </OnEnterTransition>
              </Column>
              <Row hideOnDesktop>
                <Button
                  noBorder
                  onClick={() => i18n.changeLanguage("en")}
                  css={(theme) => ({
                    textDecoration:
                      i18n.language === "en" ? "underline" : "none",
                    borderColor: theme.colors.primary,
                    color: theme.colors.primary,
                  })}
                >
                  EN
                </Button>
                <Button
                  noBorder
                  onClick={() => i18n.changeLanguage("de")}
                  css={{
                    textDecoration:
                      i18n.language === "de" ? "underline" : "none",
                    borderColor: theme.colors.primary,
                    color: theme.colors.primary,
                  }}
                >
                  DE
                </Button>
              </Row>
            </FlowBox>

            <StyledA
              hideOnDesktop
              href="https://www.instagram.com/one_nation_munich/"
              target="_blank"
            >
              <InstagramIcon height={20} fill={theme.colors.secondary} />
            </StyledA>
          </FlowBox>
          <Row hideOnMobile css={{ alignItems: "center" }}>
            <Spacer />
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/#menu">Menu</StyledLink>
            <StyledLink to="/#train-with-us">Train with us</StyledLink>
            <StyledLink to="/#contact">Contact</StyledLink>
            <StyledA
              href="https://c96717-4.myshopify.com/"
              target="_blank"
              css={(theme) => ({ color: theme.colors.primary })}
            >
              Shop
            </StyledA>

            <StyledA
              href="https://www.instagram.com/one_nation_munich/"
              target="_blank"
              css={(theme) => ({ color: theme.colors.primary })}
            >
              <InstagramIcon height={20} fill={theme.colors.secondary} />
            </StyledA>
            <Button
              noBorder
              onClick={() => i18n.changeLanguage("en")}
              css={(theme) => ({
                textDecoration: i18n.language === "en" ? "underline" : "none",
                borderColor: theme.colors.primary,
                color: theme.colors.primary,
              })}
            >
              EN
            </Button>
            <Button
              noBorder
              onClick={() => i18n.changeLanguage("de")}
              css={{
                textDecoration: i18n.language === "de" ? "underline" : "none",
                borderColor: theme.colors.primary,
                color: theme.colors.primary,
              }}
            >
              DE
            </Button>
          </Row>
        </Row>
      </Nav>
      <MobileBottomNav>
        <Row
          css={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <StyledMobileLink
            to="/"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <HomeIcon fill={theme.colors.secondary} height={24} />
            Home
          </StyledMobileLink>
          <StyledMobileLink to="/#menu">
            <SilverwareIcon fill={theme.colors.secondary} height={24} />
            Menu
          </StyledMobileLink>
          <StyledMobileLink to="/#train-with-us">
            <WeightLifterIcon fill={theme.colors.secondary} height={24} />
            Train
          </StyledMobileLink>
          <StyledMobileAnchor
            href="https://c96717-4.myshopify.com/"
            css={(theme) => ({ color: theme.colors.secondary })}
          >
            <FontAwesomeIcon
              icon={faCartShopping}
              height={24}
              css={{ fontSize: 20, margin: 2 }}
            />
            Shop
          </StyledMobileAnchor>
        </Row>
      </MobileBottomNav>
    </header>
  );
};
