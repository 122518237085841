import "typeface-raleway";

export const theme = {
  colors: {
    primary: "#ff5524",
    secondary: "#D46147",
    white: "rgb(246 241 230)",
    bgBlack: "#050000",
  },
  fonts: {
    secondary: "Raleway, Sans-serif",
  },
  fontSizes: {
    small: 14,
    medium: 16,
    big: 26,
    giant: 50,
  },
};

export type OneNationTheme = typeof theme;
