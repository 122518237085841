/** @jsxImportSource @emotion/react */
import { Column, Text, Title } from "../components"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"


const SText = styled(Text)(({theme}) => ({
    color: theme.colors.white,
    textAlign: "center",
    fontWeight: 400
}))
export const LegalPage = () => {
    const theme = useTheme();
    return (
        <Column css={{flex: 1, justifyContent: "center", alignItems: "center", paddingTop: 200, background: theme.colors.bgBlack}}>
            <Title>LEGAL NOTICE<span css={theme => ({color: theme.colors.white})}>.</span></Title>
            <SText>
                One Nation UG<br />
                Lenbachplatz 7a<br />
                80333 München
            </SText>
            <SText>E-Mail: 
                <a css={theme => ({fontSize: theme.fontSizes.medium, color: theme.colors.white, textDecoration: "none", display: "inline"})} href="mailto:info@one-nation.one">
                    <span css={{marginLeft: 4}}>info@one-nation.one</span>
                </a>
            </SText>
            <SText>Executive Board: Ilektra Lamprousi</SText>
            <SText>
                Court of Jurisdiction: Amtsgericht München<br />
                Register of Commerce: HRB 270028
            </SText>

        </Column>
    )
}