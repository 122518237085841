import { LazyLoadImage } from "react-lazy-load-image-component";

export type GPlayProps = {};
export const GPlay = ({}: GPlayProps) => {
  return (
    <a href="https://play.google.com/store/apps/details?id=com.onenation.app">
      <LazyLoadImage
        alt="Get it on Google Play"
        height="50px"
        src="/assets/images/get-on-google-play.svg"
      />
    </a>
  );
};

export type AppStoreProps = {};
export const AppStore = ({}: AppStoreProps) => {
  return (
    <a href="https://apps.apple.com/de/app/one-nation/id6479882776?l=en-GB">
      <LazyLoadImage
        alt="Get it on the App Store"
        height="50px"
        src="/assets/images/download-on-app-store.svg"
      />
    </a>
  );
};
