/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import { Trans } from "react-i18next";
import { SubTitle } from "../../components";
import { desktop } from "../../theme";

const Text = styled(SubTitle)(({ theme }) => ({
  textAlign: "center",
  color: theme.colors.bgBlack,
}));
export const Solution: React.FC<{}> = () => {
  return (
    <div
      css={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "16px",
        [desktop]: { gridTemplateColumns: "1fr 1fr 1fr" },
        backgroundColor: theme.colors.white,
        padding: "32px 5%",
        borderTop: `1px solid ${theme.colors.secondary}`,
      })}
    >
      <Text>
        <Trans i18nKey="solution.1">
          <strong>Learn to move</strong> safely and ergonomically{" "}
          <strong>while exercising.</strong>
        </Trans>
      </Text>
      <Text>
        <Trans i18nKey="solution.2">
          <strong>Learn to</strong> avoid potential injuries while{" "}
          <strong>while exercising.</strong>
        </Trans>
      </Text>

      <Text css={{ gridColumn: "1 / 3", [desktop]: { gridColumn: "auto" } }}>
        <Trans i18nKey="solution.3">
          <strong>Learn to</strong> restore your original performance after
          injury <strong>in a targeted and structured way.</strong>
        </Trans>
      </Text>
    </div>
  );
};
