/** @jsxImportSource @emotion/react */
import { Theme } from "@emotion/react";
import React, { ReactNode } from "react";
import { desktop } from "../theme";

export const Title: React.FC<
  {
    as?: React.ElementType;
    children: ReactNode;
    primary?: Boolean;
    primaryColor?: boolean;
  } & React.HTMLAttributes<HTMLHeadingElement>
> = ({
  as: Tag = "h1",
  children,
  primary = true,
  primaryColor: pc,
  ...props
}) => {
  const primaryColor = (theme: Theme) =>
    primary ? theme.colors[pc ? "primary" : "secondary"] : theme.colors.white;
  const secondaryColor = (theme: Theme) =>
    primary ? theme.colors.white : theme.colors.secondary;
  return (
    <Tag
      css={(theme: Theme) => {
        const primary = primaryColor(theme);
        const secondary = secondaryColor(theme);
        return {
          color: primary,
          fontSize: theme.fontSizes.big,
          letterSpacing: 0.2,
          textAlign: "center",
          "> strong": {
            color: secondary,
            fontWeight: 900,
          },
          [desktop]: {
            fontSize: theme.fontSizes.giant,
          },
        };
      }}
      {...props}
    >
      {children}
    </Tag>
  );
};

export const SubTitle: React.FC<
  {
    as?: React.ElementType;
    children: ReactNode;
    center?: boolean;
  } & React.HTMLAttributes<HTMLHeadingElement>
> = ({ as: Tag = "span", center, children, ...props }) => {
  return (
    <Tag
      css={(theme: Theme) => ({
        color: theme.colors.white,
        fontSize: theme.fontSizes.small,
        textAlign: center ? "center" : "start",
        "> strong": {
          color: theme.colors.secondary,
          fontWeight: 900,
        },
        [desktop]: {
          fontSize: theme.fontSizes.medium,
        },
      })}
      {...props}
    >
      {children}
    </Tag>
  );
};
