import { css, Global, Theme, useTheme } from "@emotion/react";

const breakpoints = [576, 1024];

export const [mobile, tablet] = breakpoints.map(
  (bp) => `@media (max-width: ${bp}px)`
);

export const desktop = `@media (min-width: ${breakpoints[1]}px)`;

export const hideOnMobile = css`
  @media (max-width: ${breakpoints[0]}px) {
    display: none;
  }
`;

export const hideOnDesktop = css`
  @media (min-width: ${breakpoints[1]}px) {
    display: none;
  }
`;

export const showOnDesktop = css`
  display: none;
  @media (min-width: ${breakpoints[1]}px) {
    display: block;
  }
`;

const GlobalCss = (theme: Theme) => css`
  body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: ${theme.fonts.secondary};
    font-weight: 900;
    button {
      font-family: ${theme.fonts.secondary};
      font-weight: 900;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 900;
    }
  }
`;

export const GlobalStyles = () => {
  const theme = useTheme();

  return <Global styles={GlobalCss(theme)} />;
};
