/** @jsxImportSource @emotion/react */

import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";

export const Button: React.FC<
  ButtonHTMLAttributes<any> & { noBorder?: boolean }
> = ({ noBorder = false, ...props }) => {
  return (
    <button
      css={(theme) => ({
        backgroundColor: "transparent",
        border: noBorder ? "none" : `2px solid ${theme.colors.secondary}`,
        borderRadius: 5,
        color: theme.colors.secondary,
        "> svg": {
          fill: `${theme.colors.secondary} !important`,
        },
      })}
      {...props}
    ></button>
  );
};

export const DownloadButton: React.FC<AnchorHTMLAttributes<any>> = ({
  children,
  ...props
}) => {
  return (
    <a
      css={(theme) => ({
        backgroundColor: "transparent",
        border: `2px solid ${theme.colors.secondary}`,
        borderRadius: 5,
        color: theme.colors.secondary,
        fontSize: theme.fontSizes.big,
        textDecoration: "none",
        padding: 4,
        zIndex: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      })}
      {...props}
    >
      {children}
    </a>
  );
};
